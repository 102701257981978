import { render, staticRenderFns } from "./sales-order-add.vue?vue&type=template&id=5ed3a627&scoped=true&"
import script from "./js/sales-order-add.js?vue&type=script&lang=js&"
export * from "./js/sales-order-add.js?vue&type=script&lang=js&"
import style0 from "./sales-order-add.vue?vue&type=style&index=0&id=5ed3a627&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed3a627",
  null
  
)

export default component.exports